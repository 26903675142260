<template>
  <div>
        <canvas id="canvas" style="background:linear-gradient(rgba(255,255,255, 1),rgba(56,182,230, 1) ,rgba(214,238,247, 1))"></canvas>
        <div class="container">
      <div class="login-form">
        <h1 class="h1">电子招投标系统注册</h1>
		<el-form ref="rgsForm" class="rgs-form" :model="rgsForm">
			<!-- <div v-if="tableName=='zerendanwei'" class="input-group">
			   <div class="label">单位名称</div>
			   <div class="input-container">
			     <input v-model="ruleForm.danweimingcheng" class="input" type="text" placeholder="单位名称">
			   </div>
			 </div> -->
			<el-form-item label="单位名称" class="input" v-if="tableName=='zerendanwei'">
			  <el-input v-model="ruleForm.danweimingcheng" autocomplete="off" placeholder="单位名称"  />
			</el-form-item>
			<!-- <div v-if="tableName=='zerendanwei'" class="input-group">
			   <div class="label">识别码</div>
			   <div class="input-container">
			     <input v-model="ruleForm.shibiema" class="input" type="text" placeholder="识别码">
			   </div>
			 </div> -->
			<el-form-item label="识别码" class="input" v-if="tableName=='zerendanwei'">
			  <el-input v-model="ruleForm.shibiema" autocomplete="off" placeholder="识别码"  />
			</el-form-item>
			<!-- <div v-if="tableName=='zerendanwei'" class="input-group">
			   <div class="label">密码</div>
			   <div class="input-container">
			     <input v-model="ruleForm.mima" class="input" type="text" placeholder="密码">
			   </div>
			 </div> -->
			<el-form-item label="密码" class="input" v-if="tableName=='zerendanwei'">
			  <el-input v-model="ruleForm.mima" autocomplete="off" placeholder="密码" type="password"#elsetype="text" />
			</el-form-item>
			<el-form-item label="确认密码" class="input" v-if="tableName=='zerendanwei'">
			  <el-input v-model="ruleForm.mima2" autocomplete="off" placeholder="确认密码" type="password"/>
			</el-form-item>

			<!-- <div v-if="tableName=='zerendanwei'" class="input-group">
			   <div class="label">单位电话</div>
			   <div class="input-container">
			     <input v-model="ruleForm.danweidianhua" class="input" type="text" placeholder="单位电话">
			   </div>
			 </div> -->
			<el-form-item label="单位电话" class="input" v-if="tableName=='zerendanwei'">
			  <el-input v-model="ruleForm.danweidianhua" autocomplete="off" placeholder="单位电话"  />
			</el-form-item>
			<!-- <div v-if="tableName=='zerendanwei'" class="input-group">
			   <div class="label">法人代表</div>
			   <div class="input-container">
			     <input v-model="ruleForm.farendaibiao" class="input" type="text" placeholder="法人代表">
			   </div>
			 </div> -->
			<el-form-item label="法人代表" class="input" v-if="tableName=='zerendanwei'">
			  <el-input v-model="ruleForm.farendaibiao" autocomplete="off" placeholder="法人代表"  />
			</el-form-item>
			<!-- <div v-if="tableName=='zerendanwei'" class="input-group">
			   <div class="label">联系方式</div>
			   <div class="input-container">
			     <input v-model="ruleForm.lianxifangshi" class="input" type="text" placeholder="联系方式">
			   </div>
			 </div> -->
			<el-form-item label="联系方式" class="input" v-if="tableName=='zerendanwei'">
			  <el-input v-model="ruleForm.lianxifangshi" autocomplete="off" placeholder="联系方式"  />
			</el-form-item>
			<!-- <div v-if="tableName=='zerendanwei'" class="input-group">
			   <div class="label">单位地址</div>
			   <div class="input-container">
			     <input v-model="ruleForm.danweidizhi" class="input" type="text" placeholder="单位地址">
			   </div>
			 </div> -->
			<el-form-item label="单位地址" class="input" v-if="tableName=='zerendanwei'">
			  <el-input v-model="ruleForm.danweidizhi" autocomplete="off" placeholder="单位地址"  />
			</el-form-item>
			<!-- <div v-if="tableName=='gongyingshang'" class="input-group">
			   <div class="label">供应商名称</div>
			   <div class="input-container">
			     <input v-model="ruleForm.gongyingshangmingcheng" class="input" type="text" placeholder="供应商名称">
			   </div>
			 </div> -->
			<el-form-item label="供应商名称" class="input" v-if="tableName=='gongyingshang'">
			  <el-input v-model="ruleForm.gongyingshangmingcheng" autocomplete="off" placeholder="供应商名称"  />
			</el-form-item>
			<!-- <div v-if="tableName=='gongyingshang'" class="input-group">
			   <div class="label">识别码</div>
			   <div class="input-container">
			     <input v-model="ruleForm.shibiema" class="input" type="text" placeholder="识别码">
			   </div>
			 </div> -->
			<el-form-item label="识别码" class="input" v-if="tableName=='gongyingshang'">
			  <el-input v-model="ruleForm.shibiema" autocomplete="off" placeholder="识别码"  />
			</el-form-item>
			<!-- <div v-if="tableName=='gongyingshang'" class="input-group">
			   <div class="label">密码</div>
			   <div class="input-container">
			     <input v-model="ruleForm.mima" class="input" type="text" placeholder="密码">
			   </div>
			 </div> -->
			<el-form-item label="密码" class="input" v-if="tableName=='gongyingshang'">
			  <el-input v-model="ruleForm.mima" autocomplete="off" placeholder="密码" type="password"#elsetype="text" />
			</el-form-item>
			<el-form-item label="确认密码" class="input" v-if="tableName=='gongyingshang'">
			  <el-input v-model="ruleForm.mima2" autocomplete="off" placeholder="确认密码" type="password"/>
			</el-form-item>

			<!-- <div v-if="tableName=='gongyingshang'" class="input-group">
			   <div class="label">供应商电话</div>
			   <div class="input-container">
			     <input v-model="ruleForm.gongyingshangdianhua" class="input" type="text" placeholder="供应商电话">
			   </div>
			 </div> -->
			<el-form-item label="供应商电话" class="input" v-if="tableName=='gongyingshang'">
			  <el-input v-model="ruleForm.gongyingshangdianhua" autocomplete="off" placeholder="供应商电话"  />
			</el-form-item>
			<!-- <div v-if="tableName=='gongyingshang'" class="input-group">
			   <div class="label">负责人</div>
			   <div class="input-container">
			     <input v-model="ruleForm.fuzeren" class="input" type="text" placeholder="负责人">
			   </div>
			 </div> -->
			<el-form-item label="负责人" class="input" v-if="tableName=='gongyingshang'">
			  <el-input v-model="ruleForm.fuzeren" autocomplete="off" placeholder="负责人"  />
			</el-form-item>
			<!-- <div v-if="tableName=='gongyingshang'" class="input-group">
			   <div class="label">联系电话</div>
			   <div class="input-container">
			     <input v-model="ruleForm.lianxidianhua" class="input" type="text" placeholder="联系电话">
			   </div>
			 </div> -->
			<el-form-item label="联系电话" class="input" v-if="tableName=='gongyingshang'">
			  <el-input v-model="ruleForm.lianxidianhua" autocomplete="off" placeholder="联系电话"  />
			</el-form-item>
			<!-- <div v-if="tableName=='gongyingshang'" class="input-group">
			   <div class="label">供应商地址</div>
			   <div class="input-container">
			     <input v-model="ruleForm.gongyingshangdizhi" class="input" type="text" placeholder="供应商地址">
			   </div>
			 </div> -->
			<el-form-item label="供应商地址" class="input" v-if="tableName=='gongyingshang'">
			  <el-input v-model="ruleForm.gongyingshangdizhi" autocomplete="off" placeholder="供应商地址"  />
			</el-form-item>
			<div style="display: flex;flex-wrap: wrap;width: 100%;justify-content: center;">
				<el-button class="btn" type="primary" @click="login()">注册</el-button>
				<el-button class="btn close" type="primary" @click="close()">取消</el-button>
			</div>
		</el-form>
      </div>
      <!-- <div class="nk-navigation">
        <a href="#">
          <div @click="login()">注册</div>
        </a>
      </div> -->
    </div>
  </div>
</template>
<script>

import canvasBg from "@/assets/js/canvas-bg-2.js";
import "@/assets/css/canvas-bg-2.css"

export default {
  data() {
    return {
      ruleForm: {
      },
      tableName:"",
      rules: {},
    };
  },
  mounted(){
    let table = this.$storage.get("loginTable");
    this.tableName = table;
        this.$nextTick(() => {
      canvasBg()
    })
      },
  created() {
    
  },
  methods: {
    // 获取uuid
    getUUID () {
      return new Date().getTime();
    },
    close(){
	this.$router.push({ path: "/login" });
    },
    // 注册
    login() {
	var url=this.tableName+"/register";
      if((!this.ruleForm.shibiema) && `zerendanwei` == this.tableName){
        this.$message.error(`识别码不能为空`);
        return
      }
      if((this.ruleForm.mima!=this.ruleForm.mima2) && `zerendanwei` == this.tableName){
	    this.$message.error(`两次密码输入不一致`);
	    return
      }
      if(`zerendanwei` == this.tableName && this.ruleForm.danweidianhua&&(!this.$validate.isPhone(this.ruleForm.danweidianhua))){
        this.$message.error(`单位电话应输入电话格式`);
        return
      }
      if(`zerendanwei` == this.tableName && this.ruleForm.lianxifangshi&&(!this.$validate.isMobile(this.ruleForm.lianxifangshi))){
        this.$message.error(`联系方式应输入手机格式`);
        return
      }
      if((!this.ruleForm.shibiema) && `gongyingshang` == this.tableName){
        this.$message.error(`识别码不能为空`);
        return
      }
      if((this.ruleForm.mima!=this.ruleForm.mima2) && `gongyingshang` == this.tableName){
	    this.$message.error(`两次密码输入不一致`);
	    return
      }
      if(`gongyingshang` == this.tableName && this.ruleForm.gongyingshangdianhua&&(!this.$validate.isPhone(this.ruleForm.gongyingshangdianhua))){
        this.$message.error(`供应商电话应输入电话格式`);
        return
      }
      if(`gongyingshang` == this.tableName && this.ruleForm.lianxidianhua&&(!this.$validate.isMobile(this.ruleForm.lianxidianhua))){
        this.$message.error(`联系电话应输入手机格式`);
        return
      }
      this.$http({
        url: url,
        method: "post",
        data:this.ruleForm
      }).then(({ data }) => {
        if (data && data.code === 0) {
          this.$message({
            message: "注册成功",
            type: "success",
            duration: 1500,
            onClose: () => {
              this.$router.replace({ path: "/login" });
            }
          });
        } else {
          this.$message.error(data.msg);
        }
      });
    }
  }
};
</script>
<style lang="scss" scoped>
	.el-radio__input.is-checked .el-radio__inner {
		border-color: #00c292;
		background: #00c292;
	}

	.el-radio__input.is-checked .el-radio__inner {
		border-color: #00c292;
		background: #00c292;
	}

	.el-radio__input.is-checked .el-radio__inner {
		border-color: #00c292;
		background: #00c292;
	}

	.el-radio__input.is-checked+.el-radio__label {
		color: #00c292;
	}

	.el-radio__input.is-checked+.el-radio__label {
		color: #00c292;
	}

	.el-radio__input.is-checked+.el-radio__label {
		color: #00c292;
	}

	.h1 {
		margin-top: 10px;
	}

	body {
		padding: 0;
		margin: 0;
	}

	// .container {
 //    min-height: 100vh;
 //    text-align: center;
 //    // background-color: #00c292;
 //    padding-top: 20vh;
 //    background-image: url(../assets/img/bg.jpg);
 //    background-size: 100% 100%;
 //    opacity: 0.9;
 //  }

	// .login-form:before {
	// 	vertical-align: middle;
	// 	display: inline-block;
	// }

	// .login-form {
	// 	max-width: 500px;
	// 	padding: 20px 0;
	// 	width: 80%;
	// 	position: relative;
	// 	margin: 0 auto;

	// 	.label {
	// 		min-width: 60px;
	// 	}

	// 	.input-group {
	// 		max-width: 500px;
	// 		padding: 20px 0;
	// 		width: 80%;
	// 		position: relative;
	// 		margin: 0 auto;
	// 		display: flex;
	// 		align-items: center;

	// 		.input-container {
	// 			display: inline-block;
	// 			width: 100%;
	// 			text-align: left;
	// 			margin-left: 10px;
	// 		}

	// 		.icon {
	// 			width: 30px;
	// 			height: 30px;
	// 		}

	// 		.input {
	// 			position: relative;
	// 			z-index: 2;
	// 			float: left;
	// 			width: 100%;
	// 			margin-bottom: 0;
	// 			box-shadow: none;
	// 			border-top: 0px solid #ccc;
	// 			border-left: 0px solid #ccc;
	// 			border-right: 0px solid #ccc;
	// 			border-bottom: 1px solid #ccc;
	// 			padding: 0px;
	// 			resize: none;
	// 			border-radius: 0px;
	// 			display: block;
	// 			width: 100%;
	// 			height: 34px;
	// 			padding: 6px 12px;
	// 			font-size: 14px;
	// 			line-height: 1.42857143;
	// 			color: #555;
	// 			background-color: #fff;
	// 		}

	// 	}
	// }

	.nk-navigation {
		margin-top: 15px;

		a {
			display: inline-block;
			color: #fff;
			background: rgba(255, 255, 255, .2);
			width: 100px;
			height: 50px;
			border-radius: 30px;
			text-align: center;
			display: flex;
			align-items: center;
			margin: 0 auto;
			justify-content: center;
			padding: 0 20px;
		}

		.icon {
			margin-left: 10px;
			width: 30px;
			height: 30px;
		}
	}

	.register-container {
		margin-top: 10px;

		a {
			display: inline-block;
			color: #fff;
			max-width: 500px;
			height: 50px;
			border-radius: 30px;
			text-align: center;
			display: flex;
			align-items: center;
			margin: 0 auto;
			justify-content: center;
			padding: 0 20px;

			div {
				margin-left: 10px;
			}
		}
	}

	.container {
		height: 100vh;
		background-position: center center;
		background-size: cover;
		background-repeat: no-repeat;
    
		.login-form {
			right: 50%;
			top: 50%;
			transform: translate3d(50%, -50%, 0);
			border-radius: 10px;
			background-color: rgba(255,255,255,.5);
			font-size: 14px;
			font-weight: 500;
      box-sizing: border-box;

			width: 480px;
			height: auto;
			padding: 10px;
			margin: 0px 0px 0px 0px;
			border-radius: 10px;
			border-width: 0;
			border-style: solid;
			border-color: rgba(255,0,0,0);
			background-color: rgba(255, 255, 255, 0.5);
			box-shadow: 0 0 0px rgba(255,0,0,.1);

			.h1 {
				width: 460px;
				height: 40px;
				line-height:40px;
				color: #000;
				font-size: 23px;
				padding: 0;
				margin: 0px 0px 0px 0px;
				border-radius: 0;
				border-width: 0;
				border-style: solid;
				border-color: rgba(255,0,0,0);
				background-color: rgba(144, 238, 144, 0);
				box-shadow: 0 0 6px rgba(255,0,0,0);
				text-align: center;
			}

			.rgs-form {
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;

        .el-form-item {
          width: 100%;
          display: flex;

          & /deep/ .el-form-item__content {
            flex: 1;
            display: flex;
          }
        }

				.input {
          width: 400px;
          height:auto;
          padding: 0;
          margin: 0 0 10px 0;
          border-radius: 0;
          border-width: 0;
          border-style: solid;
          border-color: rgba(255,0,0,0);
          background-color: rgba(255, 69, 0, 0);
          box-shadow: 0 0 6px rgba(255,0,0,0);

					& /deep/ .el-form-item__label {
            width: 110px;
            line-height:40px;
            color: rgba(0, 3, 9, 1);
            font-size: 14px;
            padding: 0px 0px 0px 0px;
            margin: 0px 12px 0px 0px;
            border-radius: 0;
            border-width: 0;
            border-style: solid;
            border-color: rgba(255,0,0,0);
            background-color: rgba(255,0,0,0);
            box-shadow: 0 0 6px rgba(255,0,0,0);
					}

					& /deep/ .el-input__inner {
            width: 220px;
            height: 40px;
            line-height:40px;
            color: rgba(0, 0, 0, 1);
            font-size: 14px;
            padding: 0 12px;
            margin: 0;
            border-radius: 4px;
            border-width: 1px;
            border-style: solid;
            border-color: #606266;
            background-color: #fff;
            box-shadow: 0 0 6px rgba(255,0,0,0);
            text-align: left;
					}
				}

        .send-code {
          & /deep/ .el-input__inner {
            width: 140px;
            height: 40px;
            line-height:40px;
            color: rgba(0, 0, 0, 1);
            font-size: 14px;
            padding: 0 12px;
            margin: 0;
            border-radius: 4px 0px 0px 4px;
            border-width: 1;
            border-style: solid;
            border-color: #606266;
            background-color: #fff;
            box-shadow: 0 0 6px rgba(255,0,0,0);
            text-align: left;
          }

          .register-code {
            margin: 0;
            padding: 0;
            width: 80px;
            height: 40px;
            line-height:40px;
            color: #fff;
            font-size: 14px;
            border-width: 0;
            border-style: solid;
            border-color: rgba(255,0,0,0);
            border-radius: 0;
            background-color: rgb(64, 158, 255);
            box-shadow: 0 0 6px rgba(255,0,0,0);
          }
        }

				.btn {
					margin: 0 10px;
          padding: 0;
					width: 88px;
					height: 44px;
          line-height:44px;
					color: rgba(0, 0, 0, 1);
					font-size: 14px;
					border-width: 0;
					border-style: solid;
					border-color: rgba(64, 158, 255, 1);
					border-radius: 4px;
					background-color: rgba(64, 158, 255, 1);
          box-shadow: 0 0 0px rgba(255,0,0,0);
				}

				.close {
          margin: 0 10px;
          padding: 0;
          width: 88px;
          height: 44px;
          line-height:44px;
          color: rgba(0, 0, 0, 1);
          font-size: 14px;
          border-width: 0;
          border-style: solid;
          border-color: rgba(64, 158, 255, 1);
          border-radius: 4px;
          background-color: rgba(64, 158, 255, 1);
          box-shadow: 0 0 0px rgba(255,0,0,0);
				}

			}
		}
	}
</style>
