<template>
  <div>
    <!-- 上传文件组件 -->
    <el-upload
      ref="upload"
      :action="getActionUrl"
      list-type="text"
      accept=".xls,.xlsx,.doc,.docx,.pdf"
      :limit="limit"
      :headers="myHeaders"
      :file-list="fileList"
      :on-exceed="handleExceed"
      :on-preview="handleUploadPreview"
      :on-remove="handleRemove"
      :on-success="handleUploadSuccess"
      :on-error="handleUploadErr"
      :before-upload="handleBeforeUpload"

    >
    <div  slot="trigger" style="margin-top: 40px;">
      <el-button size="small" type="primary">点击上传</el-button>
     <div slot="tip" class="el-upload__tip" style="color:#838fa1;">{{tip}}</div>
    </div>
    </el-upload>
    <el-dialog :visible.sync="dialogVisible" size="tiny" append-to-body>
      <!-- <img width="100%" :src="dialogImageUrl" alt> -->
      <iframe :src="dialogImageUrl" width="100%" height="600px"></iframe>
    </el-dialog>
  </div>
</template>
<script>
import storage from "@/utils/storage";
import base from "@/utils/base";
export default {
  data() {
    return {
      // 查看大图
      dialogVisible: false,
      // 查看大图
      dialogImageUrl: "",
      // 组件渲染图片的数组字段，有特殊格式要求
      fileList: [],
      fileUrlList: [],
      myHeaders:{}
    };
  },
  props: ["tip", "action", "limit", "multiple", "fileUrl","fileName"],
  mounted() {
    this.init();
    this.myHeaders= {
      'Token':storage.get("Token")
    }
  },
  watch: {
    fileUrl: function(val, oldVal) {
      //   console.log("new: %s, old: %s", val, oldVal);
      this.init();
    }
  },
  computed: {
    // 计算属性的 getter
    getActionUrl: function() {
      // return base.url + this.action + "?token=" + storage.get("token");
      return `/${this.$base.name}/` + this.action;
    }
  },
  methods: {
    // 初始化
    init() {
      //   console.log(this.fileUrls);
      if (this.fileUrl) {
        // this.fileUrlList = this.fileUrls.split(",");
        let fileArray = [];
        // this.fileUrlList.forEach(function(item, index) {
        //   var url = item;
        //   var name = index;
        //   var file = {
        //     name: name,
        //     url: url
        //   };
        //   fileArray.push(file);
        // });
        // this.setFileList(fileArray);
        this.fileList  = [
          {
            name: this.fileName,
            url: this.fileUrl
          }
        ];
      }
    },
    handleBeforeUpload(file) {
    
    },
    // 上传文件成功后执行
    handleUploadSuccess(res, file, fileList) {
      if (res && res.code === 0) {
        console.log(res, file, fileList)
        // fileList[fileList.length - 1]["url"] = "upload/" + file.response.file;
        // this.setFileList(fileList);
        this.fileList = [
          {
            name: res.data.name,
            url: res.data.url,
          }
        ]
        this.$emit("change", this.fileList[0]);
        this.$message.success("文件导入成功");
      } else {
        this.$message.error(res.msg);
      }
    },
    // 图片上传失败
    handleUploadErr(err, file, fileList) {
      this.$message.error("文件导入失败");
    },
    // 移除图片
    handleRemove(file, fileList) {
      this.fileList = [];
      // this.setFileList(fileList);
      this.$emit("change", []);
    },
    // 查看大图
    handleUploadPreview(file) {
      console.log('file',file)
      window.open(file.url)
      // this.dialogImageUrl = file.url;
      // this.dialogVisible = true;
    },
    // 限制图片数量
    handleExceed(files, fileList) {
      this.$message.warning(`最多上传${this.limit}个文件`);
    },
    // 重新对fileList进行赋值
    setFileList(fileList) {
    //   var fileArray = [];
    //   var fileUrlArray = [];
    //   // 有些图片不是公开的，所以需要携带token信息做权限校验
    //   var token = storage.get("token");
    //   let _this = this;
    //   fileList.forEach(function(item, index) {
    //     var url = item.url.split("?")[0];
    // if(!url.startsWith("http")) {
    //   url = _this.$base.url+url
    // }
    //     var name = item.name;
    //     var file = {
    //       name: name,
    //       url: url + "?token=" + token
    //     };
    //     fileArray.push(file);
    //     fileUrlArray.push(url);
    //   });
    //   this.fileList = fileArray;
    //   this.fileUrlList = fileUrlArray;
      fileList.forEach(function(item, index) {
     
        var file = {
          name: name,
          url: url + "?token=" + token
        };
        fileArray.push(file);
      });
    this.fileList = fileArray;
    }
  }
};
</script>
<style lang="scss" scoped>
</style>

