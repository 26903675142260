import axios from 'axios'
import router from '@/router/router-static'
import storage from '@/utils/storage'
// 基础配置
import base from '@/utils/base'
const http = axios.create({
    timeout: 1000 * 86400,
    withCredentials: true,
    // baseURL: '/springbootbtw32',
    baseURL: base.get().url ,
    headers: {
        'Content-Type': 'application/json; charset=utf-8'
    }
})
// 请求拦截
http.interceptors.request.use(config => {
    config.headers['Token'] = storage.get('Token') // 请求头带上token
    return config
}, error => {
    return Promise.reject(error)
})
// 响应拦截
http.interceptors.response.use(response => {


    let disposition = response.headers['content-disposition'];
    //以下部分有需要优化，如后端返回时没有携带文件后缀名,没有.时会有问题等等

    if(disposition) {
      let extName = disposition.substring(disposition.indexOf('.')+1)
      if(extName == 'xls' || extName == 'xlsx'){
        let filename = disposition?disposition.substring(disposition.indexOf('=')+1,disposition.indexOf('.')):"下载文件";
        let newName = decodeURI(escape(filename))
        let blob = new Blob([response.data],{type: 'application/vnd.ms-excel'});
        let link = document.createElement("a");
        let evt = document.createEvent("HTMLEvents");
        evt.initEvent("click", false, false);
        link.href = URL.createObjectURL(blob);
        link.download = newName+"."+extName;
        link.style.display = "none";
        document.body.appendChild(link);
        link.click();
        window.URL.revokeObjectURL(link.href);
        return true;
      }
    }

    if (response.data && response.data.code === 401) { // 401, token失效
        router.push({ name: 'login' })
    }
    return response
}, error => {
    return Promise.reject(error)
})
export default http